.card-stat.google-ads-stats {
  h2,
  p {
    color: #fff;
  }
  .media {
    margin-top: 15px;
  }
  .clicks {
    .card {
      background-color: #ffbc01;
    }
  }
  .impresions {
    .card {
      background-color: #e8a000;
    }
  }
  .conversion {
    .card {
      background-color: #b57e00;
    }
  }
  .budget {
    .card {
      background-color: #8d6100;
    }
  }
}

.card-stat.bing-ads-stats {
  h2,
  p {
    color: #fff;
  }
  .media {
    margin-top: 15px;
  }
  .clicks {
    .card {
      background-color: #01897b;
    }
  }
  .impresions {
    .card {
      background-color: #016b5f;
    }
  }
  .conversion {
    .card {
      background-color: #014e46;
    }
  }
  .budget {
    .card {
      background-color: #01342d;
    }
  }
}

.card-stat.facebook-ads-stats {
  h2,
  p {
    color: #fff;
  }
  .media {
    margin-top: 15px;
  }
  .clicks {
    .card {
      background-color: #487aaf;
    }
  }
  .impresions {
    .card {
      background-color: #3c5f85;
    }
  }
  .conversion {
    .card {
      background-color: #2b405b;
    }
  }
  .budget {
    .card {
      background-color: #192637;
    }
  }
}
