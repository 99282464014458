.card-stat {
  h2,
  p {
    color: #fff;
  }
  .media {
    margin-top: 15px;
  }
  .clicks {
    .card {
      background-color: #ff5241;
    }
  }
  .impresions {
    .card {
      background-color: #62e0f6;
    }
  }
  .conversion {
    .card {
      background-color: #01e4ac;
    }
  }
  .budget {
    .card {
      background-color: #fe842e;
    }
  }
}
